.home-stats{
   text-align: center;
   display: flex;
   margin-top: 5%;
   margin-bottom: 3%;
   justify-content: center;
}

.home-stats .item{
   display: flex;
   flex-wrap: wrap;
   margin-right: 15px;
   margin-left: 15px;
}

.home-stats .col-md-4{
   display: inline-flex;
}

.home-stats .col{
   position: relative;
   width: 100%;
   padding-right: 15px;
   padding-left: 15px;
   flex-basis: 0;
   flex-grow: 1;
   max-width: 100%;
}

.list-categories-header{
   box-sizing: border-box;
   margin: 0;
   display: inline-block;
}

.list-categories-header .highlighted-span {
   font-size: 40px;
   font-weight: 700;
   line-height: 1.1;
   color: #034184;
}

.stat-title{
   font-size: 20px;
   color: #034184;
}

@media only screen and (max-width: 960px) {
    .home-stats .col-md-4{
        margin-top: 4%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: 150px;
        grid-gap: 10px;
    }

    .item:nth-child(-1n+3) {
        grid-column: span 4;
    }

    .item:nth-last-child(-n+2) {
        grid-row-start: 2;
        grid-column: 3 / span 4;
    }

    .item:nth-last-child(1) {
        grid-row-start: 2;
        grid-column: 7 / span 4;
    }
    .list-categories-header .highlighted-span {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.1;
      color: #034184;
   }
   .stat-title{
       font-size: 16px;
       color: #034184;
    }

   .home-stats{
      text-align: center;
      display: flex;
   }
 }

@media only screen and (max-width: 480px) {
    .home-stats .col-md-4{
        display: block;
    }
    .item {
        margin-bottom: 2rem;
    }
}

