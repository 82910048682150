.result-wrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container {
  /* min-height: 87vh; */
  display: block;
  text-align: center;
  width: 65vw;
  margin-bottom: 80px;
  padding-bottom: 50px;
  padding-top: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  animation: Show 0.4s;
  animation-fill-mode: forwards;
  position: static;
  margin: 5% auto;
  line-height:44px;
}

.table-container {
  margin: 0 4%;
  text-align: left;
}

h2 {
  padding: 2% 0 3% 0;
  font-size: 30px;
  text-align: center;
  color: black;
  font-weight: 700;
}

tr:nth-child(odd) {
  background-color: rgba(242,242,242,0.5);
}

td:nth-child(odd) {
  padding-left: 10px;
}

.table-container table td:nth-child(even) {
  padding-left: 50px;
  padding-right: 10px;
}

table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

.back {
  font-size: 16px;
  margin: 1% 0 0 1%;
  color: #003476;
  transition: color 0.5s;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  background: none!important;
  border: none;
  padding: 0!important;
}

.back:hover,
.back:hover .fas {
  color: #00aeef;
  transition: color 0.5s;
}

@media only screen and (max-width: 960px) {
  .table-container {
    overflow-x: auto;
  }
}