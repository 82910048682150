.results-container {
    display: grid;
    width:95%;
    margin-top:20px;
    margin-left: auto;
    margin-right: auto;
    gap: 40px;
    grid-template-columns: 350px 1fr;
    grid-template-areas: "filters results"
}

.results-container .filters {
    grid-area: filters;
}

.results-container .results {
    grid-area: results;
}

.resultsSearchWrapper {
    position: relative;
    height: 70px;
    margin: 25px auto 10px auto;
}
.grid-form {
    display: grid;
    grid-template-areas: "box button";
    grid-template-columns: 290px 1fr;
}

.resultsSearchWrapper input[type='text'] {
    grid-area: box;
    border: 2px solid #003476;
    padding: 15px 15px 15px 20px;
    font-size: 16px;
    border-radius: 5px;
}

.resultsSearchWrapper button {
    grid-area: button;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    border: 0;
    color: #fff;
    background-color: #003476;
    margin: 0 10px;
    border: 0;
    transition: 0.3s;
}


.generalSearchWrapper {
    flex: 0 1 auto;
    margin:0 auto;
    width:20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align:center;
}

.generalSearchWrapper input {
    line-height: 25px;
    padding:2px 10px;
    border:solid 1px #ccc;
    border-radius: 10px;
}

#searchBtn {
    border: 0;
    background: none;
}


.resultsWrapper {
    min-height: 750px;
}

/* place spinner */
.resultsWrapper .spinner {
    position: absolute;
    left: 50%;
    right: 0;
    top:40%;
    width:300px;
}

.resultsTable {
    margin-top: 1.5%;
    width:100%;
    text-align: left;
    /* table-layout: fixed; */
}

.resultsTable thead {
    background-color: rgba(0, 0, 0, .1);
}

.resultsTable tbody {
    width:100%;
    font-size: 15px;
}


.resultsTable th {
    text-align: left;
    padding: 10px 10px;
}


.resultsTable td {
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
}

.resultsTable .wide {
    width:28%;
}

.filters .filterset h2 {
    background-color: #003476;
    color: #fff;
    font-size: 21px;
    padding-top: 3%;
    padding-bottom: 3%;
}

.filterset .finalized {
    padding-bottom: 10px;
}

.filters label {
    display: block;
}

.filters #minedu_id {
    display: block;
    width:100%;
    height: 36px;
    box-sizing:border-box;
    margin-bottom: 5px;
    border:1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    padding: 15px 20px 15px 10px;
    font-size: 16px;
}

.filters #useFiltersBtn {
    border-radius: 5px;
    padding: .46647em 1.2828em;
    cursor: pointer;
    border: 0;
    font-size: 1rem;
    color: #fff;
    background-color: #003476;
    margin: 10px 0;
    border: 0;
    transition: 0.3s;
}

.no-data {
    color: #003476;
    text-align: center;
    font-size: x-large;
}

.tableFooter {
    display: block;
    width:100%;
    margin-top: 5px;
}


/* for displaying result number */
.countResultsSection {
    padding-left: 10px;
    opacity: 1;
    transition: opacity 0.5s linear;
}

    .countResultsSection p {
        margin-bottom: 0;
    }

/* Pagination */
.paginationWrapper {
    width:100%;
    display: block;  
}

.centerPagination {
    display: table;
	margin: 0 auto;
}

.pagination {
    list-style-type: none;
    padding: 0; margin: 0;
    height: 55px;
    line-height: 55px;
}

.pagination li {
    float: left;
    user-select: none;
    -moz-user-select: none;
}

.pagination a {
    cursor: pointer;
}

.pagination li a {
    padding: 6px;
}

.pagination li.active a {
    background-color: #003476 !important;
    color: white;
}

.pagination li:focus {
    outline:0 !important;
    border:0 !important;
}

@media only screen and (max-width: 1125px) {
    .results-container {
        display: block;
        width:95%;
        margin-top:20px;
        margin-left: auto;
        margin-right: auto;
        gap: 40px;
        grid-template-columns: 350px 1fr;
        grid-template-areas: "filters results"
    }
    .filters .filterset h2 {
        padding: 1% 5%;
        margin: 20px 0;
    }
    .grid-form {
        display: grid;
        grid-template-areas: "box button";
        grid-template-columns: 6fr 1fr;
    }
    .results{
        min-width: 300px;
        overflow-x: auto;
    }
}

