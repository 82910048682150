@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700&display=swap');

/* 
@font-face {
  font-family: 'Josefin Sans';
  src: url('/fonts/Josefin_Sans/JosefinSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Questrial';
  src: url('/fonts/Questrial/Questrial-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GFS Neohellenic';
  src: url('/fonts/GFS_Neohellenic/GFSNeohellenic.ttf') format('truetype');
}

@font-face {
  font-family: 'GFS Neohellenic Bold';
  src: url('/fonts/GFS_Neohellenic/GFSNeohellenicBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Share Tech Mono';
  src: url('/fonts/ShareTechMono-Regular.ttf') format('truetype');
} */

html, #root {
  height: 100%;
}

body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Roboto Slab',"Roboto","Open Sans",sans-serif;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

  /* .box .row {
    border: 1px dotted #0313fc;
  } */

  .box .row.header {
    flex: 0 1 auto;
  }

  .box .row.content {
    flex: 1 1 auto;
  }

  .box .row.footer {
    flex: 0 1 40px;
  }

h2 {
  color: #353f55ad;
}

button {
  cursor: pointer;
}

.Section .button {
  max-width: 70% !important;
}


.turnLighter:hover {
  transition: 0.3s;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.turnLighter:active {
  transition: 0.1s;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.4);
}

#material .description > a {
  color: #fff !important;
  background-color: #003476 !important;
  border: 0px !important;
  padding: 20px 80px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 90%;
}

#material .description > a:hover {
  background-color: #002452 !important;
}

#material .card {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#material li {
  font-weight: bold;
}

#material ul {
  list-style: none;
  margin-top: -5px;
  margin-left: 1em;
}

#material li:before {
  content: '';
  border-color: transparent #003476;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}

a {
  color: #003476;
  transition: color 0.5s;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

a .fas {
  transition: color 0.5s;
}

a:hover,
a:hover .fas {
  color: #00aeef;
  transition: color 0.5s;
}

#Landing,
.Page {
  width: 100%;
  margin: 0 0;
  height: 100vh;
  background-color: black;
}

.Page .container-header {
  margin-bottom: 50px !important;
}

.Page .content {
  padding-left: 40px;
  padding-right: 40px;
}

#App.redirect-ready .Section {
  height: 100vh;
  box-shadow: none !important;
}

.Section {
  z-index: 1;
  display: inline-block;
  width: 50%;
  height: calc(100vh - 50px);
  position: static;
  box-shadow: inset 0 -10px 10px -10px #2f2f2f;
}

.Section.full-width .Homecard {
  opacity: 0;
  transition: opacity 0.2s;
}

.Faded {
  opacity: 0.6;
  transition: opacity 0.6s ease-in;
}

.strong {
  font-weight: 800;
}

.with-description {
  border-bottom: 1px dotted rgb(100, 100, 100);
}

.Section-left {
  /* Rust   background-color: #B56357;*/
  background-color: #f6f6f6;
}

.Section-right {
  /* Pine foresty background-color: #86BC96;*/

  background-color: #e4e4e4;
  background-color: #e7e7e7;
}

.More {
  display: inline-block;
  z-index: 2;
  width: 100%;
  height: 80px;
  bottom: 0;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
  position: absolute;
}

.More:hover {
  cursor: pointer;
  height: 80px;
  padding-top: 20px;
  font-size: 20px;
  transition: height 0.4s ease-in-out, font-size 0.4s ease-in,
    padding-top 0.4s ease-in-out;
}

.App #logout {
  margin-top: 5px;
}

.compact {
  padding: 0 !important;
}

.Homecard {
  position: relative;
  top: 23% !important;
}

.Homecard svg {
  height: 34vh;
}

.Cardicon {
  font-size: 76px;
  color: #2f2f2f;
}

.Description {
  margin: 0 auto;
  min-width: 50%;
  max-width: 400px;
  padding-bottom: 15px;
}

.Description h2,
h1 {
  color: #000;
  padding: 0 0 0 0;
  margin-bottom: 0;
}

.Description .fas {
  color: #888;
  color: #00aeef;
  color: #019aeb;
  font-size: 200px;
  margin-top: 40px;
}

.Description .text-area p {
  color: #000;
}

.Description .text-area {
  height: 82px;
  display: table;
  table-layout: fixed;
}

.Description p {
  color: white;
  font-size: 18px;
  display: table-cell;
  vertical-align: middle;
}

input,
button,
select {
  font-family: 'Segoe UI', 'Roboto', sans-serif;
}

.block {
  display: block;
}

.custom-hidden {
  opacity: 0;
  transition: opacity 0.3s;
}

.Help .Help-Content h1 {
  color: #000;
  font-size: 2.7em;
  font-weight: normal;
}

.Help,
.More {
  background-color: #fff;
}

.Help .Help-Content i,
#description .card .header {
  color: #003476 !important;
}

.Help {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 0px;
}

.Help .Help-Content {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 4px;
}

.Help .nav {
  grid-area: hnav;
  background-color: #f9f9f9;
  margin-left: 10%;
  padding-top: 20px;
  width: 100%;
  padding-left: 15%;
  position: relative;
}

.Help p {
  text-indent: 0px;
  font-size: 16px;
}

.content-section {
  margin-bottom: 100px;
}

.split {
  column-count: 2;
  column-gap: 70px;
  column-rule: 2px solid lightblue;
}

.Help-Gray {
  background-color: #f1f1f1;
}

.content-section-paragraphs {
  margin-bottom: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
}

.content-section-paragraphs > h2 {
  display: inline-block;
}

#Nav-content {
  width: 250px;
  z-index: 2;
  transition: all 0.5s;
  position: sticky;
  top: 30px;
}

#Nav-content h2 {
  color: #000;
}

#Nav-content ul {
  list-style: none;
  padding-left: 10px;
  margin-top: 40px;
}

#Nav-content li {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.9;
  padding-bottom: 20px;
}

#Nav-content li:nth-child(1),
#Nav-content li:nth-child(2) {
  border-bottom: 1px solid rgba(167, 179, 165, 0.692);
}

.Help .Help-Content .ui.cards {
  width: 95%;
  margin: 0 auto;
  margin-top: 40px !important;
}

.ui.card,
.ui.cards > .card {
  background: transparent !important;
  margin: auto;
  min-height: 340px;
  border-radius: 0.2rem !important;
  cursor: default !important;
  width: 25%;
  min-width: 200px;
}

.ui.cards > .card:hover {
  transform: initial !important;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
}

.ui.card,
.ui.cards > .card .header {
  text-align: center;
  color: #353f55 !important;
  font-family: 'Roboto Slab', sans-serif !important;
}

.ui.card,
.ui.cards > .card .image {
  background: transparent !important;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.ui.card,
.ui.cards > .card .description {
  padding: 0 24px;
  font-size: 15px;
}

.Help .Help-Content i {
  color: #353f55d5;
}

.fancy {
  color: #fff !important;
  background-color: #003476 !important;
  border: 0px !important;
  padding: 20px 40px;
  font-size: 18px;
}

.fancy .fas {
  color: #fff;
}

.fancy:hover {
  background-color: #002452 !important;
}

.fancy:hover .animatable {
  padding-left: 6px;
  transition: padding 0.1s ease-in-out;
}

.center {
  text-align: center;
}

#organization-link,
#graduate-link {
  display: none;
  background-color: transparent;
}

#graduate-link:hover .fas,
#organization-link:hover .fas {
  color: #353f55 !important;
}

#org-more {
  display: none;
}

#e {
  color: #019aeb !important;
  color: #c3c3c3 !important;
  color: #ffffff !important;
}

#developer-header {
  font-family: 'Share Tech Mono', monospace;
  font-size: 42px;
}

.Results {
  padding-top: 10%;
}

.ExternalRedirect {
  display: flex;
}

.ExternalRedirect #spinner {
  margin: auto;
}

.genericError h3 {
  color: #b56357;
  margin-top: 7vh;
}

.genericError .buttons {
  margin-top: 50px !important;
}

.genericError .buttons .button {
  width: 204px;
}

.Help #nav-content h2,
.Help #nav-content hr {
  z-index: 2;
}

.presentationDownload {
  margin-left: 10px;
}

.Help .doc-links.ui.cards .card {
  margin: 0;
  width: 300px;
  margin-right: 35px;
  padding: 0;
  padding-bottom: 15px;
  min-height: 0;
  margin-top: 10px;
}

.Help .doc-links.ui.cards .card .image {
  height: 250px;
}

.Help .doc-links.ui.cards .card .description {
  padding: 0;
  padding-top: 20px;
  text-align: center;
}

.Help .doc-links.ui.cards .card img {
  height: auto;
  width: 200px;
  margin: 0 auto;
}

#newspaper-image,
#book-image,
#demo-image,
#presentation-image {
  padding-top: 25px !important;
  height: 170px !important;
  width: auto !important;
  margin: 0 auto;
}

.doc-links .card:not(.press) a {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  display: inline;
  border-radius: 5px;
  min-width: 180px;
}

.press.card {
  margin: 0;
  width: 300px;
  margin-right: 35px;
  padding: 0;
  padding-bottom: 15px;
  min-height: 0;
  text-align: left;
}

.press.card ul {
  text-align: left;
  padding-left: 50px;
}

@media only screen and (max-width: 1497px) {
  .Help .ui.cards .card {
    width: 30%;
    min-height: 365px;
  }
}

@media only screen and (max-width: 1297px) {
  .Help .ui.cards .card {
    margin-bottom: 20px;
    width: 90%;
    max-width: 400px;
    min-height: 327px;
  }
}

@media (max-resolution: 200dpi) and (max-width: 500px) {
  .Homecard:nth-child(2) {
    margin-top: 0 !important;
  }

  .Homecard {
    width: 95% !important;
  }

  .Description h2 {
    font-size: 16px !important;
    margin-top: 3px;
  }

  .Description p {
    font-size: 15px !important;
  }

  .Header h1 {
    font-size: 27px !important;
  }
}

@media only screen and (max-width: 782px) {
  .Description .fas {
    font-size: 130px;
  }

  .img-logo {
    height: 65px;
    margin-right: -10px;
  }

  #img-govgr {
    width: 100px;
  }

  #graduate {
    margin-top: 60px !important;
  }

  .App {
    position: relative;
    width: 100vw;
    overflow-x: hidden;
  }

  .Section {
    display: block;
    width: 100% !important;
    height: calc(100vh - 50px);
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
  }

  .Homecard .compact.row {
    width: 100vw;
  }

  .Section .compact.row #organization-link {
    position: absolute !important;
    bottom: 50px;
    width: 100vw !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .Section .compact.row #graduate-link {
    position: absolute !important;
    top: 10px;
    width: 100vw !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .Homecard svg {
    min-height: 150px;
    height: 29vh;
  }

  .Section:nth-child(2) {
    display: block;
    width: 100% !important;
    height: calc(100vh - 100px);
  }

  .Section:nth-child(2) .Homecard {
    top: 15% !important;
  }

  #More {
    display: block;
    background-color: rgb(252, 252, 252);
    position: absolute;
    top: 0;
  }

  .More:hover {
    display: inline-block;
    z-index: 2;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    padding-top: 5px;
    position: absolute;
    color: #353f55;
  }

  .More:hover > *,
  .More:hover .fas {
    color: #353f55 !important;
  }

  .Help {
    min-height: 100vh;
    background-color: rgb(252, 252, 252);
    display: block;
    padding-top: 80px;
    padding-bottom: 90px;
    z-index: 1;
    position: relative;
  }

  #Nav-content {
    position: static;
    width: 80%;
    padding-left: 20px;
  }
  .Help .nav {
    margin-top: 20px;
    padding-left: 0;
  }

  .Help .Help-Content {
    padding-right: 30px;
    padding-left: 30px;
  }

  .Help .content-section {
    margin-bottom: 50px;
  }

  #Landing {
    height: auto;
  }

  .Homecard {
    width: 85%;
    margin: 0 auto;
    top: 15% !important ;
  }

  .Homecard .Description p {
    font-size: 17px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }
}

@media only screen and (max-width: 670px) {
  .Section {
    min-height: 520px !important;
  }

  .Section .Description {
    max-width: 500px;
  }

  .Section .compact.row #organization-link {
    margin-bottom: -70px !important;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }

  .Help .nav {
    padding-left: 0;
  }

  .Homecard {
    width: 85%;
    margin: 0 auto;
  }

  .Homecard .Description p {
    font-size: 14px;
  }
  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  #eDiplomasLogo .logo-text {
    font-size: 20px;
    padding: 0;
    margin: 0;
  }

  .split {
    column-count: 2;
    column-gap: 70px;
    column-rule: 2px solid lightblue;
  }
}

@media only screen and (max-width: 350px) and (max-height: 500px) {
  .Section:nth-child(1) {
    display: block;
    width: 100% !important;
    height: 450px;
  }

  .Section:nth-child(2) {
    display: block;
    width: 100% !important;
    height: 450px;
  }
}

@media only screen and (max-width: 688px) {
  .Description h2 {
    font-size: 17px !important;
  }

  .Description p {
    font-size: 14px !important;
  }
}

@media only screen and (max-height: 650px) and (min-width: 782px) {
  .Homecard:nth-child(2) {
    margin-top: 0 !important;
  }

  .Homecard {
    width: 95% !important;
  }

  #Landing,
  .Section {
    height: 630px;
  }

  /* .Section {
    display: block;
  } */

  .More {
    top: 630px;
  }

  .Help {
    margin-top: 0;
    padding-top: 80px;
  }

  .Description h2 {
    font-size: 16px !important;
  }

  .Description p {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 590px) {
  .genericError .buttons {
    margin-top: 30px !important;
  }

  .genericError .buttons .button {
    margin: 0 auto !important;
  }

  .genericError .buttons .button,
  .genericError .buttons .back-link {
    display: block;
  }

  .genericError .buttons .back-link {
    position: relative;
    top: 80px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 355px) {
  /*iphone 5 landing cards*/

  .Help .Help-Content {
    padding-right: 35px;
    padding-left: 25px;
  }
}

@media only screen and (min-width: 355px) and (max-width: 365px) {
  /*specific fix for landing cards edge case */

  .Help .Help-Content {
    padding-right: 35px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 365px) and (max-width: 380px) {
  /*specific fix for landing cards edge case */

  .Help .Help-Content {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 315px) and (max-height: 517px) and (min-height: 435px) {
  .Homecard {
    margin: 0 4px;
  }

  .Help .Help-Content {
    padding-left: 20px;
    padding-right: 20px;
    width: 90%;
  }

  .Section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 470px !important;
  }

  .Homecard {
    width: 100vw !important;
    margin-left: 0;
    margin-right: 0;
  }

  .Description {
    width: calc(100vw - 6px);
    padding-left: 5px;
    padding-right: 5px;
  }

  .Section p {
    width: 100%;
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}

@media only screen and (max-width: 480px) {
  /* medium phones */
  .content-section {
    padding-top: 1rem;
  }

  .content-section-paragraphs {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }

  .split {
    column-count: 1;
    column-gap: 70px;
    column-rule: 2px solid lightblue;
  }

  .Page .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 670px) {
  /* big phones */
  .Description p {
    padding-left: 45px !important;
  }
}

@media only screen and (max-width: 480px) and (max-height: 850px) {
  /* usual phone screens */
  #Nav-content {
    position: static;
    top: calc(450px + 180px);
    width: 80%;
    padding-left: 20px;
  }

  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  .Homecard .Description p {
    font-size: 17px;
  }
  #organization-link {
    margin-top: 0px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }
}

@media only screen and (max-width: 480px) and (max-height: 950px) {
  /* usual phone screens */

  .Homecard {
    height: 95% !important;
  }

  .Section:nth-child(1) {
    display: block;
    width: 100% !important;
    margin-top: 150px;
    height: 470px;
  }

  .Section-right {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
  }

  .Section-left {
    padding-bottom: 90px !important;
    padding-top: 20px !important;
  }

  .Section:nth-child(2) {
    display: block;
    width: 100% !important;
    height: 470px;
  }

  .Section .Description {
    max-width: 300px;
  }

  .Help {
    min-height: 517px;
    display: block;
    padding-top: 70px;
    padding-bottom: 90px;
    z-index: 1;
  }

  #img-govgr {
    right: 5px;
    top: 25px;
  }

  .Header h1 {
    padding-bottom: 0;
  }

  #Nav-content {
    position: static;
    top: calc(450px + 180px);
    width: 80%;
    padding-left: 20px;
  }

  .container-header {
    position: relative;
    top: 50px;
    font-size: 20px;
    text-align: center;
    padding: 15px;
  }

  .Homecard .Description p {
    font-size: 17px;
  }
  #organization-link {
    margin-top: 0px;
  }

  #organization-link,
  #graduate-link {
    display: block;
  }
}

@media only screen and (max-width: 315px) {
  .Homecard svg {
    width: 150px;
  }
}

@media only screen and (min-width: 670) and (max-width: 870px) {
  /* rotation */

  .Description p {
    padding-left: 25px !important;
  }
}
