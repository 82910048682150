.Footer {
  background-color: #003476;
  position: relative;
  height: 250px;
  padding: 30px 30px;
  z-index: 1;
  height: auto;
}

.Footer a {
  color: #fafafa;
  transition: color 0.5s;
  font-weight: normal !important;
}

.Footer a:hover {
  color: #00aeef;
  transition: color 0.5s;
}

.Footer li a {
  font-size: 16px;
}

.Footer .left.content {
  position: relative;
}

.Footer .right.content {
  position: absolute;
  float: right;
  margin-top: 0;
  right: 0;
  bottom: 40px;
}

.Footer .left.content ul {
  list-style: none;
  width: 391px;
  padding-left: 0;
}

.Footer .left.content {
  display: inline-block;
  margin-left: 40px;
}

.Footer .left.content ul {
  margin-left: 0;
}

.Footer .right.content {
  display: inline-block;
  margin-right: 40px;
  margin-top: 300px;
  color: rgb(267, 264, 237);
}

.Footer .left.content ul > li {
  margin-bottom: 25px;
  color: rgb(267, 264, 237);
  font-size: 16px;
  border-bottom: 1px solid rgb(267, 264, 237);
  padding-bottom: 6px;
}

.Footer .content .header {
  color: rgb(267, 264, 237);
}

.Footer .left.content .header {
  margin-bottom: 30px;
  width: 391px;
}

.Footer .subheader {
  margin-right: 10px;
}

.Footer .fas {
  color: rgb(267, 264, 237) !important;
}

.Footer #contact,
.Footer #info,
.Footer #services {
  margin-bottom: 40px;
}

.Footer #contact .header,
.Footer #info .header,
.Footer #services .header {
  margin-bottom: 15px;
}

.Footer #info a,
.Footer #services a {
  font-weight: 900;
}

#FooterLogo {
  height: 103.5px;
  width: 182.5px;
}

#website-url {
  margin-top: 15px;
}

@media only screen and (min-width: 1600px) {
  .Footer {
    position: relative;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .Footer .left.content {
    width: calc(90% - 182.5px);
  }

  .Footer .left.content > div {
    display: inline-block;
    position: relative;
    margin-right: 5%;
    margin-bottom: 20px !important;
    vertical-align: top;
    transition: all 0.9s;
  }

  .Footer .left.content h3 {
    font-size: 14px !important;
  }

  .Footer .left.content > div li {
    font-size: 14px !important;
  }

  .Footer .right.content {
    margin-top: 0;
    position: absolute;
    bottom: 50px;
  }
}

@media only screen and (max-width: 782px) {
  .Footer .left.content ul {
    width: 90%;
  }

  .Footer > * {
    padding-right: 20px;
  }

  .Footer h3.header {
    width: 70vw !important;
  }

  .Footer .left.content {
    width: 70vw;
  }

  .Footer .right.content {
    width: 70vw;
    margin-left: 40px;
    margin-top: 30px;
    display: block;
    float: none;
    position: static;
  }

  .Footer .right.content hr {
    width: 90%;
    position: relative;
    margin-left: 0;
  }
}

@media only screen and (min-width: 1290px) {
  .Footer {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 688px) {
  .Footer hr {
    width: 70%;
    min-width: 182.5px;
    position: relative;
    margin-left: 0;
  }

  .Footer {
    height: auto;
  }
}

@media only screen and (max-width: 315px) {
  .Footer .left.content,
  .Footer .right.content {
    margin-left: 0;
    width: 90vw !important;
  }

  .Footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .Footer {
    box-sizing: border-box;
    width: 100vw;
  }
}

@media only screen and (max-width: 1776px) {
  /*fixes smaller screens footer bug */

  .Footer {
    height: auto !important;
  }
}
