.Header .header-area {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  padding-left: 15px;
  height: 115px;
}

.Header .header-area a {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  padding-left: 15px;
  position: relative;
}

.Header {
  width: 100%;
  background-color: #003476;
  z-index: 2;
  display: block;
  flex: 0 1 auto;
  /* border-bottom: 4px solid #00aeef; */
}

.header-border {
  width: 100%;
  height: 4px;
  background: linear-gradient(358.5deg, #00aeef 0.09%, #134a86);
}

.Header .logged_in_info {
  font-weight: 700;
  color: #353f55;
}

.Header .logged_in_info + .row {
  margin-top: 15px;
}

.Header .userMenu.mobile {
  display: none;
}

#mobile-menu {
  display: none;
}

.Header .userMenu.full {
  position: absolute;
  right: 0;
  top: 85px;
  padding-right: 20px;
}

.Header .userMenu.full #clientName {
  margin-right: 13px;
}

.Header .userMenu.full span {
  font-size: 14px;
  color: #3a4252;
  font-weight: 700;
}

.Header .userMenu.full .fas {
  margin-right: 5px;
}

#clientName span {
  color: rgb(220, 220, 220);
}

.userMenu {
  z-index: 2;
}
.userMenu a,
.userMenu a span {
  color: #00aeef !important;
}

.logo-area {
  height: 60px;
  display: block;
}

.img-logo {
  height: 50px;
  margin-right: 5px;
}

#img-minedu {
  position: relative;
  top: 10px;
}

.logo-text {
  font-size: 24px;
}

.logo-split {
  border-right: 1px solid white;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#img-govgr {
  width: 100px;
  position: absolute;
  top: 15px;
  right: 19px;
}

.language-switch {
  position: absolute;
  color: #fff !important;
  z-index: 3;
  width: 150px;
  text-align: right;
  top: 50px;
  right: 20px;
  font-weight: bold;
}

.language-switch button {
  color: #fff !important;
}

.language-switch button:hover {
  color: #00aeef !important;
  border-bottom: 2px solid #00aeef;
}

.language-switch button.active {
  border-bottom: 2px solid #00aeef;
  color: #00aeef !important;
}

@media only screen and (max-width: 782px) {
  .Header .userMenu.full {
    display: none;
  }

  .Header .userMenu.mobile {
    display: block;
    position: absolute;
    top: 80px;
    right: 3px;
    padding-right: 20px;
    z-index: 4;
    background-color: #003476;
  }

  .Header .menu-button {
    padding: 0px;
    text-align: center;
  }

  .Header .menu-button .fas {
    transition: color 0.3s;
    color: #ffffff;
    font-size: 25px;
  }

  .Header .menu-button:hover .fas {
    color: #00aeef;
    transition: color 0.3s;
  }

  #mobile-menu {
    position: absolute;
    background-color: #003476;
    width: 50%;
    right: 0;
    display: block;
  }

  #mobile-menu ul {
    list-style: none;
    padding-left: 0;
    text-align: center;
    min-height: 140px;
    min-width: 180px;
  }

  #mobile-menu li {
    color: #fff;
    padding: 20px 30px;
    display: inline-block;
  }

  #mobile-menu li a {
    color: #fff;
    text-decoration: underline;
  }

  #mobile-menu li:nth-child(1) {
    border-bottom: 1px solid rgb(119, 119, 121);
  }
}

@media only screen and (max-width: 550px) {
  /* medium phones */

  #eDiplomasLogo {
    position: relative;
    display: block;
    left: 0;
    top: -30px;
    width: 200px;
  }

  #img-minedu {
    width: 200px;
    height: auto;
    margin: 1px auto;
    padding-right: 15px;
    left: 0;
    top: -25px !important;
  }

  .logo-split {
    display: none;
  }
}
