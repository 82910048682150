
.page-container {
    display: block;
    width:80%;
    margin: 0 auto;
    height:100%;
    text-align: center;
}

.page-container h1 {
    margin-top: 25px;
    text-align: left;
}


.page-container .redirectText {
    text-align: left;
    margin-top: 10px;
}

.page-container .dashboardRedirect {
    color: #0148a1;
    font-weight: bold;
}

.page-container .dashboardRedirect:hover {
    color: #00aeef;
}

.page-container .description {
    text-align: left;
    margin-top: 50px;
}

.inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
}

#searchInput {
    padding:10px 20px;
    border: 2px solid #003476;
    padding: 15px 15px 15px 20px;
    font-size: 22px;
    width: 500px;
    border-radius: 5px;
}

#searchBtn {
    border: 0;
    background: none;
}

.searchExtra {
    padding-top: 30px;
}

.searchArea {
    display: block;
    margin: 0 auto;
    max-width: 600px;
}

button.primary {
    font-family: 'Roboto Slab',"Roboto","Open Sans",sans-serif;
    border-radius: 5px;
    padding: 17px 10px 16px 10px;
    cursor: pointer;
    border: 0;
    color: #fff;
    background-color: #003476;
    /* border: 2px solid #003476; */
    /* border-top-left-radius: 0;
    border-bottom-left-radius: 0; */
    /* margin-left: -5px; */
    margin: 0 10px;
    border: 0;
    transition: 0.3s;
    font-size: 19px;
}

button.secondary {
    font-size: 19px;
    font-family: 'Roboto Slab',"Roboto","Open Sans",sans-serif;
    border-radius: 5px;
    padding: 17px 10px 16px 10px;
    cursor: pointer;
    border: 0;
    color: #000;
    background-color: #eee;
    /* border: 2px solid #003476; */
    /* border-top-left-radius: 0;
    border-bottom-left-radius: 0; */
    /* margin-left: -5px; */
    margin: 0 10px;
    border: 0;
    transition: 0.3s;
}

/* #searchBtn:hover .fa-search {
    transition:0.4s;
    text-shadow: 3px 3px 16px #c3c2c4;
}

#searchBtn:active .fa-search {
    transition:0.1s;
    text-shadow: 3px 3px 16px #8f8d91;
} */

@media only screen and (max-width: 480px) {
    #searchInput {
        width: 200px;
        border-radius: 5px;
        font-size: 13px;
        padding: 15px 15px 15px 15px;
    }
}